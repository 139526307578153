import { blocklyExtensionsAndMutators, blocklyInputDropdown } from './constants';

const DCDInputs = [
  {
    type: 'input_set',
    message0: 'Input %1 %2',
    args0: [
      {
        type: 'input_dummy',
        name: blocklyInputDropdown,
      },
      {
        type: 'input_statement',
        name: 'NAME',
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 210,
    tooltip: '',
    helpUrl: '',
    extensions: [blocklyExtensionsAndMutators.inputExtenstion],
    mutator: blocklyExtensionsAndMutators.inputMutator,
  },
  {
    type: 'input_get',
    message0: 'Input %1',
    args0: [
      {
        type: 'input_dummy',
        name: blocklyInputDropdown,
      },
    ],
    output: null,
    colour: 210,
    tooltip: '',
    helpUrl: '',
    extensions: [blocklyExtensionsAndMutators.inputExtenstion],
    mutator: blocklyExtensionsAndMutators.inputMutator,
  },
];
export default DCDInputs;
