import { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react';
import Blockly, { BlockSvg } from 'blockly';
import blocklyConfig from './blocklyConfig';
import toolbox from './toolbox';
import DCDcustomBlocks from './DCDcustomBlocks';
import { debounce } from 'lodash';
import { NOTIFICATION_STATUSES, useNotifications } from '@adsk/alloy-react';
import text from '../../../Common/global/text/text.json';
import DCDInputs from './DCDInputs';
import DataContext from '../../context/DataStore/Data.context';
import { BlocklyState } from './BlocklyModule.types';
import {
  blocklyExtensionsAndMutators,
  blocklyInputDropdown,
  blocklyToolboxInputsCategory,
} from './constants';

//Initialize custom blocks definition
Blockly.defineBlocksWithJsonArray(DCDcustomBlocks);
Blockly.defineBlocksWithJsonArray(DCDInputs);

interface useBlocklyModuleProps {
  initialState?: BlocklyState;
}

interface useBlocklyModuleReturn {
  ref: (node: HTMLDivElement) => void;
  currentState: BlocklyState;
}

const useBlocklyModule = ({ initialState }: useBlocklyModuleProps): useBlocklyModuleReturn => {
  const { currentDraft } = useContext(DataContext);

  const blocklyRef = useRef<Blockly.WorkspaceSvg>();
  const [blocklyWorkspace, setBlocklyWorkspace] = useState<Blockly.WorkspaceSvg>();
  const [currentState, setCurrentState] = useState<BlocklyState>({});
  const [initialStateLoaded, setInitialStateLoaded] = useState(false);

  const { showNotification } = useNotifications();

  //Initialize Blockly Workspace
  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (node && !initialStateLoaded) {
        const workspace = Blockly.inject(node, {
          ...blocklyConfig,
          toolbox,
        });

        workspace.registerToolboxCategoryCallback(blocklyToolboxInputsCategory, () =>
          DCDInputs.map((block) => ({ kind: 'block', type: block.type })),
        );

        blocklyRef.current = workspace;
        // Set initial workspace state
        setBlocklyWorkspace(workspace);

        return () => {
          //Clean up Blockly
          if (workspace) {
            workspace.dispose();
            setBlocklyWorkspace(undefined);
          }
        };
      }
    },
    [initialStateLoaded],
  );

  // Setup extension to load Input dropdowns dynamically
  useEffect(() => {
    //When Inputs change, we need to add new dropdown options
    if (Blockly.Extensions.isRegistered(blocklyExtensionsAndMutators.inputExtenstion)) {
      Blockly.Extensions.unregister(blocklyExtensionsAndMutators.inputExtenstion);
    }

    //Add new Input options by dynamically creating a dropdown.
    Blockly.Extensions.register(
      blocklyExtensionsAndMutators.inputExtenstion,
      function (this: BlockSvg) {
        const block = this.getInput(blocklyInputDropdown);
        if (block) {
          // TODO: Add validation to for every input type to make sure
          //       only matching operations can be mixed. For example:
          //       if a user tries to mix a numeric input with a boolean operation,
          //       they will get an error.

          block.appendField(
            new Blockly.FieldDropdown(() => {
              const inputs = [...currentDraft.parameters.map((input) => [input.name, input.name])];
              return inputs;
            }),
          );
        }
      },
    );

    //Cleanup extensions on unmount
    return () => {
      Blockly.Extensions.unregister(blocklyExtensionsAndMutators.inputExtenstion);
    };
  }, [currentDraft.parameters]);

  // Setup mutator to corretly serialize blockly state
  useEffect(() => {
    if (!Blockly.Extensions.isRegistered(blocklyExtensionsAndMutators.inputMutator)) {
      Blockly.Extensions.registerMutator(blocklyExtensionsAndMutators.inputMutator, {
        saveExtraState(this: BlockSvg) {
          const block = this.getInput(blocklyInputDropdown);
          if (block) {
            const inputValue = block.fieldRow[1].getValue();
            return {
              dropdown_value: inputValue,
            };
          }
        },
        loadExtraState(this: BlockSvg, state: BlocklyState) {
          const block = this.getInput(blocklyInputDropdown);
          if (block) {
            block.fieldRow[1].setValue(state.dropdown_value);
          }
        },
      });
    }

    //Cleanup
    return () => {
      Blockly.Extensions.unregister(blocklyExtensionsAndMutators.inputMutator);
    };
  }, []);

  //Load initial state, if provided
  useEffect(() => {
    if (!initialStateLoaded && initialState && blocklyWorkspace) {
      try {
        Blockly.serialization.workspaces.load(initialState, blocklyWorkspace);
      } catch (e) {
        //If initial state is invalid, start with an empty state
        showNotification({
          message: text.blocklyInvalidInitialState,
          status: NOTIFICATION_STATUSES.ERROR,
        });
      }
      const loadedState = Blockly.serialization.workspaces.save(blocklyWorkspace);
      setCurrentState(loadedState);
      setInitialStateLoaded(true); //Only load initial state once
      blocklyWorkspace.cleanUp(); //Organize blocks
    }
  }, [initialState, initialStateLoaded, blocklyWorkspace, showNotification]);

  // Update currentState when Blockly workspace changes
  const _handleWorkspaceChange = useMemo(
    () =>
      debounce(() => {
        if (blocklyWorkspace) {
          const state = Blockly.serialization.workspaces.save(blocklyWorkspace);
          setCurrentState(state);
        }
      }, 500),
    [blocklyWorkspace],
  );

  // Cancel pending debounces when the component unmounts
  useEffect(
    () => () => {
      _handleWorkspaceChange.cancel();
    },
    [_handleWorkspaceChange],
  );

  // Listen to Blockly events
  useEffect(() => {
    if (blocklyWorkspace) {
      blocklyWorkspace.addChangeListener(_handleWorkspaceChange);
      return () => {
        blocklyWorkspace.removeChangeListener(_handleWorkspaceChange);
      };
    }
  }, [blocklyWorkspace, _handleWorkspaceChange]);

  return {
    ref,
    currentState,
  };
};

export default useBlocklyModule;
