import { useContext, useState, useEffect } from 'react';
import { BlocklyState } from './BlocklyModule.types';
import DataContext from '../../context/DataStore/Data.context';
import { generateMinMaxBlock, generateBooleanLabelsBlock } from './utils';
import { TemplateInputType } from '../../../lib/interfaces/dynamicContent';

interface useGenerateBlocklyStateReturn {
  inputState: BlocklyState;
}

const useGenerateBlocklyState = (): useGenerateBlocklyStateReturn => {
  const { currentDraft } = useContext(DataContext);
  const [blocklyBlocks, setBlocklyBlocks] = useState<BlocklyState[]>([]);
  useEffect(() => {
    const generatedBlocks = currentDraft.parameters.reduce<BlocklyState[]>((acc, input) => {
      switch (input.type) {
        case TemplateInputType.Numeric: {
          const minMaxBlock = generateMinMaxBlock({
            name: input.name,
            min: input?.min,
            max: input?.max,
            increment: input?.increment,
          });
          acc.push(minMaxBlock);
          break;
        }
        case TemplateInputType.Boolean: {
          const labelsBlock = generateBooleanLabelsBlock({
            name: input.name,
            trueLabel: input?.trueLabel,
            falseLabel: input?.falseLabel,
          });
          acc.push(labelsBlock);
          break;
        }
      }
      return acc;
    }, []);
    setBlocklyBlocks(generatedBlocks);
  }, [currentDraft.parameters]);

  return {
    inputState: {
      blocks: {
        languageVersion: 0,
        blocks: blocklyBlocks,
      },
    },
  };
};

export default useGenerateBlocklyState;
