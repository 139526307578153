import {
  GenerateOutputsResult,
  InventorInput,
  InventorOutput,
} from '../interfaces/inventorAutomation';
import { HostApi } from '../interfaces/cefSharp';
import { InventorProperties } from '../interfaces/inventorProperties';

declare let hostApi: HostApi;

export const getPartOrAssemblyProperties = async (path: string): Promise<InventorProperties> => {
  const inventorPropertiesString = await hostApi.getPartOrAssemblyProperties(path);
  const inventorPropertiesJson: InventorProperties = JSON.parse(inventorPropertiesString);

  return inventorPropertiesJson;
};

export const getModelStates = async (documentPath: string): Promise<string[]> => {
  const modelStates = await hostApi.getModelStates(documentPath);
  return JSON.parse(modelStates);
};

export const generateOutputs = async (
  topFolderPath: string,
  documentPath: string,
  inputs: InventorInput[],
  requestedOutputs: InventorOutput[],
): Promise<GenerateOutputsResult> => {
  const inputsJson = JSON.stringify(inputs);
  const requestedOutputsJson = JSON.stringify(requestedOutputs);
  const generateOutputsResultJson = await hostApi.generateOutputs(
    topFolderPath,
    documentPath,
    inputsJson,
    requestedOutputsJson,
  );

  return JSON.parse(generateOutputsResultJson);
};
