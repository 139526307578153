import React from 'react';
import { LeftSection } from '../../../../Common/global/styles/Header/Header.styles';
import { Button, ICON_TYPES } from '@adsk/alloy-react';
import text from '../../../../Common/global/text/text.json';
import { LeftButton } from '../../../../Common/global/styles/Common/Common.styles';

const SourceContent: React.FC<{
  handleNewTemplateClick: () => void;
  handleOpenSavedDraftsClick: () => void;
}> = ({ handleNewTemplateClick, handleOpenSavedDraftsClick }): JSX.Element => (
  <>
    <LeftSection>
      <LeftButton
        icon={ICON_TYPES.PLUS_CIRCLE_FILLED}
        onClick={handleNewTemplateClick}
        size={Button.SIZES.SMALL}
      >
        {text.buttonNewTemplate}
      </LeftButton>
      <LeftButton
        icon={ICON_TYPES.BOOKMARK_FILLED}
        onClick={handleOpenSavedDraftsClick}
        size={Button.SIZES.SMALL}
      >
        {text.buttonSavedDrafts}
      </LeftButton>
    </LeftSection>
  </>
);

export default SourceContent;
