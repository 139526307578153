import {
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
  DraftTemplateReducerMap,
  OutputType,
} from '../../../lib/interfaces/templates';
import { initialDraftTemplateReducer } from './dataStore';
import { CurrentDraftTemplateActions, CurrentDraftTemplateActionTypes } from './dataStore.actions';

const _parametersArrayToMap = (array: DraftTemplateInputParameter[]) => {
  const map = new Map<string, DraftTemplateInputParameter>();
  array.forEach((item) => {
    map.set(item.name, item);
  });
  return map;
};

const _iPropertiesArrayToMap = (array: DraftTemplateIProperty[]) => {
  const map = new Map<string, DraftTemplateIProperty>();
  array.forEach((item) => {
    map.set(item.id, item);
  });
  return map;
};

export const draftTemplateReducer = (
  draftTemplate: DraftTemplateReducerMap,
  action: CurrentDraftTemplateActions,
): DraftTemplateReducerMap => {
  switch (action.type) {
    case CurrentDraftTemplateActionTypes.SET_DRAFT: {
      const { draft } = action.payload;
      const parametersMap = _parametersArrayToMap(draft.parameters);
      const iPropertiesMap = _iPropertiesArrayToMap(draft.iProperties);

      return { ...draft, parameters: parametersMap, iProperties: iPropertiesMap };
    }
    case CurrentDraftTemplateActionTypes.SET_NAME: {
      const { newDraftName } = action.payload;
      return { ...draftTemplate, name: newDraftName };
    }
    case CurrentDraftTemplateActionTypes.SET_SOURCE_MODEL: {
      const { topLevelFolder, inventorProject, assembly, thumbnail } = action.payload;
      return { ...draftTemplate, topLevelFolder, inventorProject, assembly, thumbnail };
    }
    case CurrentDraftTemplateActionTypes.SET_TABLE: {
      const { tableFile, data } = action.payload;
      return { ...draftTemplate, table: { name: tableFile, data } };
    }
    case CurrentDraftTemplateActionTypes.SET_PUBLISH_LOCATION: {
      const { account, project, folder } = action.payload;
      return { ...draftTemplate, account, project, folder };
    }
    case CurrentDraftTemplateActionTypes.ADD_PARAMETERS: {
      const { newParameters } = action.payload;
      const parametersMap = _parametersArrayToMap(newParameters);
      return { ...draftTemplate, parameters: parametersMap };
    }
    case CurrentDraftTemplateActionTypes.ADD_IPROPERTIES: {
      const { newIProperties } = action.payload;
      const iPropertiesMap = _iPropertiesArrayToMap(newIProperties);
      return { ...draftTemplate, iProperties: iPropertiesMap };
    }
    case CurrentDraftTemplateActionTypes.UPDATE_PARAMETER: {
      const { parameterToUpdate, updatedValue } = action.payload;
      const clonedParametersMap = new Map(draftTemplate.parameters);
      const updatedParameter = {
        ...parameterToUpdate,
        ...updatedValue,
      };
      clonedParametersMap.set(parameterToUpdate.name, updatedParameter);
      return { ...draftTemplate, parameters: clonedParametersMap };
    }
    case CurrentDraftTemplateActionTypes.UPDATE_IPROPERTY: {
      const { iPropertyToUpdate, updatedValue } = action.payload;
      const clonedIPropertiesMap = new Map(draftTemplate.iProperties);
      const updatedIProperty = {
        ...iPropertyToUpdate,
        ...updatedValue,
      };
      clonedIPropertiesMap.set(iPropertyToUpdate.id, updatedIProperty);
      return { ...draftTemplate, iProperties: clonedIPropertiesMap };
    }
    case CurrentDraftTemplateActionTypes.UPDATE_OUTPUT: {
      const { outputType, updatedValue } = action.payload;
      const nonTypeOutputs = draftTemplate.outputs.filter((output) => output.type !== outputType);

      if (outputType === OutputType.BOM) {
        // return without BOM output if no model state is selected
        if (!updatedValue.options?.modelStates?.length) {
          return { ...draftTemplate, outputs: [...nonTypeOutputs] };
        }
      }
      return { ...draftTemplate, outputs: [...nonTypeOutputs, updatedValue] };
    }
    case CurrentDraftTemplateActionTypes.DELETE_TABLE: {
      return { ...draftTemplate, table: undefined };
    }
    case CurrentDraftTemplateActionTypes.RESET_DRAFT: {
      return { ...initialDraftTemplateReducer };
    }
    default:
      throw new Error('No Draft Template action found');
  }
};
