import { TemplateInputType } from '../../../../../../lib/interfaces/dynamicContent';
import {
  DraftTemplateBooleanInput,
  DraftTemplateInputParameter,
} from '../../../../../../lib/interfaces/templates';
import { DependsOnConstraint } from '../../../../../../lib/interfaces/inputConstraints';
import { HandleSelectedInputUpdateType } from '../../useInputsTab';
import logger from '../../../../../../Common/global/logger';
import text from '../../../../../../Common/global/text/text.json';
import { useEffect, useState } from 'react';

interface UseDependencyRulesState {
  controllingInputDropdownItems: string[];
  currentControllingInputDropdownValue: string;
  handleSelectControllingInput: (newValue: string | number) => void;
}
interface useDependencyRulesArgs {
  selectedParameters: DraftTemplateInputParameter[];
  selectedParameterName?: string;
  handleSelectedInputDataStoreUpdate: HandleSelectedInputUpdateType;
}

export const useDependencyRulesDropdown = ({
  selectedParameters,
  selectedParameterName,
  handleSelectedInputDataStoreUpdate,
}: useDependencyRulesArgs): UseDependencyRulesState => {
  const [controllingInputDropdownItems, setControllingInputDropdownItems] = useState<string[]>([]);
  const [currentControllingInputDropdownValue, setCurrentControllingInputDropdownValue] =
    useState<string>(text.none);
  useEffect(() => {
    const booleanSelectedParameters: DraftTemplateBooleanInput[] = selectedParameters.reduce(
      (prevParams: DraftTemplateBooleanInput[], param: DraftTemplateInputParameter) => {
        if (
          param.type === TemplateInputType.Boolean &&
          !param.readOnly &&
          param.name !== selectedParameterName
        ) {
          prevParams.push(param);
        }
        return prevParams;
      },
      [],
    );

    const controllingInputDropdownItems = [
      text.none,
      ...booleanSelectedParameters.map((item) => item.name),
    ];
    const selectedControllingInput = booleanSelectedParameters.find((input) =>
      input.onChange?.find(
        (dependencies) =>
          dependencies.dependsOn.bindings.dependentValue === `${selectedParameterName}.visible`,
      ),
    );
    setControllingInputDropdownItems(controllingInputDropdownItems);
    setCurrentControllingInputDropdownValue(selectedControllingInput?.name || text.none);
  }, [selectedParameterName, selectedParameters]);

  const _removeDependencyRule = (
    rules: DependsOnConstraint[],
    removeRuleName: string,
  ): DependsOnConstraint[] =>
    rules.reduce((prevRules: DependsOnConstraint[], curRule: DependsOnConstraint) => {
      if (curRule.dependsOn.bindings.dependentValue !== `${removeRuleName}.visible`) {
        prevRules.push(curRule);
      }
      return prevRules;
    }, []);

  const _findSelectedControllingInput = (
    controllingInput: string,
  ): DraftTemplateBooleanInput | undefined =>
    selectedParameters.reduce(
      (prevParam: DraftTemplateBooleanInput | undefined, param: DraftTemplateInputParameter) => {
        if (param.name === controllingInput && param.type === TemplateInputType.Boolean) {
          prevParam = param;
        }
        return prevParam;
      },
      undefined,
    );

  const _removeDependencyRuleFromSelectedInput = (
    controllingInput: string,
    removeRuleName: string,
  ) => {
    const controllingParameter: DraftTemplateBooleanInput | undefined =
      _findSelectedControllingInput(controllingInput);

    if (!controllingParameter) {
      const errorMessage = 'Fail to find the selected controlling input.';
      logger.error(errorMessage, { controllingInput, selectedParameters });
      throw new Error(errorMessage);
    }

    handleSelectedInputDataStoreUpdate(controllingParameter, {
      onChange: _removeDependencyRule(controllingParameter.onChange || [], removeRuleName),
    });
  };

  const _addDependencyRuleToSelectedInput = (controllingInput: string, newRuleName: string) => {
    const controllingParameter: DraftTemplateBooleanInput | undefined =
      _findSelectedControllingInput(controllingInput);

    if (!controllingParameter) {
      const errorMessage = 'Fail to find the selected controlling input.';
      logger.error(errorMessage, { controllingInput, selectedParameters });
      throw new Error(errorMessage);
    }

    handleSelectedInputDataStoreUpdate(controllingParameter, {
      onChange: [
        ...(controllingParameter.onChange || []),
        {
          dependsOn: {
            bindings: {
              dependentValue: `${newRuleName}.visible`,
              dependsOn: `${controllingParameter.name}.value`,
            },
          },
        },
      ],
    });
  };

  const handleSelectControllingInput = (newControllingInputName: string | number) => {
    // It should only be triggered when param input is selected
    if (selectedParameterName) {
      const isNewControllingInputEmpty = newControllingInputName === text.none;
      const isPreviousControllingInputEmpty = currentControllingInputDropdownValue === text.none;
      setCurrentControllingInputDropdownValue(newControllingInputName.toString());
      // 1. If the selected param input has not set boolean dependency rules
      //    and user selects `None`, then do nothing
      // 2. If the selected param input has already set boolean dependency rule
      //    and user selects `None`, then remove selected param input from boolean
      //    input's dependency rules
      if (isNewControllingInputEmpty && !isPreviousControllingInputEmpty) {
        _removeDependencyRuleFromSelectedInput(
          currentControllingInputDropdownValue,
          selectedParameterName,
        );
        return;
      }

      // 3. If the selected param input has already set boolean dependency rule
      //    and user selects a different controlling input,
      //    then remove selected param input from boolean input's dependency
      //    rules and add to new boolean input's dependency rules.
      if (
        !isNewControllingInputEmpty &&
        !isPreviousControllingInputEmpty &&
        currentControllingInputDropdownValue !== newControllingInputName
      ) {
        // Remove from current boolean input
        _removeDependencyRuleFromSelectedInput(
          currentControllingInputDropdownValue,
          selectedParameterName,
        );

        // Add to new boolean input
        _addDependencyRuleToSelectedInput(
          newControllingInputName.toString(),
          selectedParameterName,
        );
        return;
      }
      // 4. If the selected param input has not set boolean dependency rule
      //    and user selects a controlling input,
      //    then add to new boolean input's dependency rules.
      if (!isNewControllingInputEmpty && isPreviousControllingInputEmpty) {
        // Add to new boolean input
        _addDependencyRuleToSelectedInput(
          newControllingInputName.toString(),
          selectedParameterName,
        );
        return;
      }
    } else {
      logger.error('Fail to select the controlling input!', {
        selectedParameterInfo: selectedParameterName,
      });
      throw new Error('Fail to select the controlling input!');
    }
  };

  return {
    controllingInputDropdownItems,
    currentControllingInputDropdownValue,
    handleSelectControllingInput,
  };
};

export default useDependencyRulesDropdown;
