import React, { useContext, useEffect, useState } from 'react';
import text from '../../../Common/global/text/text.json';
import { PublishStatus } from '../../../lib/interfaces/templates';
import DataContext from '../../context/DataStore/Data.context';
import ModalContext from '../../../Common/context/GlobalModal.context';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import { HeaderWrapper, TopHeader } from '../../../Common/global/styles/Header/Header.styles';
import Initial from './SubHeaders/Initial';
import PublishingHeader from './SubHeaders/PublishingHeader';
import { PublishLocationScreenHeader } from './SubHeaders/PublishLocationScreenHeader';
import SelectDataset from './SubHeaders/SelectDataset';
import SourceContent from './SubHeaders/SourceContent';
import { initialModalState } from '../../../Common/context/modalStore';
import TabProgressContext from '../../context/TabProgressStore/TabProgress.context';
import { TABS } from '../../context/TabProgressStore/tabProgressStore';
import { TabProgress } from '../../types';
import { VersionInfo } from '../../../Common/components/VersionInfo/VersionInfo';
import { getAssemblyVersion } from '../../../lib/utils/tools';
import { InventorAddInMoniker } from '../../../Common/global/constants';

const unsavedChangesWarningMessage = (
  <>
    <div>{text.unsavedChangesLossWarning}</div>
    <div>{text.unsavedChangesContinueQuestion}</div>
  </>
);

const Header: React.FC = (): JSX.Element => {
  const { currentScreen, setCurrentScreen } = useContext(NavigationContext);
  const { draftHasUnsavedChanges, resetCurrentDraft, currentDraftPublishStatus } =
    useContext(DataContext);
  const { tabProgress } = useContext(TabProgressContext);
  const { setModalState } = useContext(ModalContext);
  const [assemblyVersion, setAssemblyVersion] = useState<string | undefined>(undefined);

  useEffect(() => {
    getAssemblyVersion().then((testAssemblyVersion) => {
      setAssemblyVersion(testAssemblyVersion);
    });
  }, []);

  const sourceContentProgress: TabProgress = tabProgress[TABS.SOURCE_CONTENT];

  // Should be used inside a `WithConfirmation` handler
  const _handleNewTemplateClick = () => {
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
    resetCurrentDraft();
  };

  // Should be used inside a `WithConfirmation` handler
  const _handleOpenSavedDraftsClick = () => {
    setCurrentScreen(Screens.DRAFT_TEMPLATES);
    resetCurrentDraft();
  };

  const handleNewTemplateClickWithConfirmation = (): void => {
    if (draftHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: _handleNewTemplateClick,
      });
    } else {
      _handleNewTemplateClick();
    }
  };

  const handleOpenSavedDraftsClickWithConfirmation = (): void => {
    if (draftHasUnsavedChanges) {
      setModalState({
        ...initialModalState,
        isOpen: true,
        message: unsavedChangesWarningMessage,
        onConfirmCallback: _handleOpenSavedDraftsClick,
      });
    } else {
      _handleOpenSavedDraftsClick();
    }
  };

  const handleEditTemplateClick = () => {
    setCurrentScreen(Screens.DRAFT_CONFIGURATION);
  };

  return (
    <HeaderWrapper>
      <TopHeader>
        {currentScreen === Screens.DRAFT_TEMPLATES && (
          <Initial handleNewTemplateClick={handleNewTemplateClickWithConfirmation} />
        )}
        {currentScreen === Screens.DRAFT_CONFIGURATION &&
          sourceContentProgress === TabProgress.EMPTY && (
            <SelectDataset
              handleOpenSavedDraftsClick={handleOpenSavedDraftsClickWithConfirmation}
            />
          )}
        {currentScreen === Screens.DRAFT_CONFIGURATION &&
          sourceContentProgress === TabProgress.COMPLETED && (
            <SourceContent
              handleNewTemplateClick={handleNewTemplateClickWithConfirmation}
              handleOpenSavedDraftsClick={handleOpenSavedDraftsClickWithConfirmation}
            />
          )}
        {currentScreen === Screens.PUBLISHING &&
          currentDraftPublishStatus === PublishStatus.IDLE && (
            <PublishLocationScreenHeader handleEditTemplateClick={handleEditTemplateClick} />
          )}
        {currentScreen === Screens.PUBLISHING &&
          currentDraftPublishStatus === PublishStatus.LOADING && (
            <PublishingHeader subtitle={text.subHeaderPublishingInProgress} />
          )}
        {currentScreen === Screens.PUBLISHING &&
          currentDraftPublishStatus === PublishStatus.COMPLETE && (
            <PublishingHeader subtitle={text.subHeaderPublishingComplete} />
          )}
        {currentScreen === Screens.PUBLISHING &&
          currentDraftPublishStatus === PublishStatus.FAILURE && (
            <PublishingHeader subtitle={text.subHeaderPublishingFailed} />
          )}
        <VersionInfo
          IVTW_VERSION={process.env.REACT_APP_IVTW_VERSION}
          IVTA_VERSION={assemblyVersion ? `v${assemblyVersion}+${InventorAddInMoniker}` : undefined}
        />
      </TopHeader>
    </HeaderWrapper>
  );
};

export default Header;
