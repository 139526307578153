import { useEffect, useState } from 'react';
import {
  DraftTemplateInputParameter,
  DraftTemplateOutput,
} from '../../../lib/interfaces/templates';
import { TabProgress } from '../../types';
import {
  updateOutputsProgressState,
  updateParametersProgressState,
  updateSourceContentProgressState,
} from './tabProgressStore.utils';

export enum TABS {
  SOURCE_CONTENT = 'SOURCE_CONTENT',
  INPUTS = 'INPUTS',
  TABLES = 'TABLES',
  RULES = 'RULES',
  OUTPUTS = 'OUTPUTS',
}

export interface TabProgressStore {
  tabProgress: {
    [TABS.SOURCE_CONTENT]: TabProgress;
    [TABS.INPUTS]: TabProgress;
    [TABS.TABLES]: TabProgress;
    [TABS.RULES]: TabProgress;
    [TABS.OUTPUTS]: TabProgress;
  };
}

interface UseTabProgressStore {
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  parameters: DraftTemplateInputParameter[];
  outputs: DraftTemplateOutput[];
}

export const useTabProgressStore = ({
  topLevelFolder,
  inventorProject,
  assembly,
  parameters,
  outputs,
}: UseTabProgressStore): TabProgressStore => {
  const [sourceContentProgress, setSourceContentProgress] = useState(TabProgress.EMPTY);
  const [inputsProgress, setInputsProgress] = useState(TabProgress.EMPTY);
  const [outputsProgress, setOutputsProgress] = useState(TabProgress.EMPTY);

  // SourceContent Progress State
  useEffect(() => {
    const sourceContentProgressState = updateSourceContentProgressState({
      topLevelFolder,
      inventorProject,
      assembly,
    });
    setSourceContentProgress(sourceContentProgressState);
  }, [topLevelFolder, inventorProject, assembly]);

  // Parameters Progress State
  useEffect(() => {
    const parametersProgressState = updateParametersProgressState(parameters);
    setInputsProgress(parametersProgressState);
  }, [parameters]);

  // Outputs Progress State
  useEffect(() => {
    const outputsProgressState = updateOutputsProgressState(outputs);
    setOutputsProgress(outputsProgressState);
  }, [outputs]);

  return {
    tabProgress: {
      [TABS.SOURCE_CONTENT]: sourceContentProgress,
      [TABS.INPUTS]: inputsProgress,
      // TODO, Add useStates for both TABLES and RULES when we have completed
      [TABS.TABLES]: TabProgress.EMPTY,
      [TABS.RULES]: TabProgress.EMPTY,
      [TABS.OUTPUTS]: outputsProgress,
    },
  };
};
