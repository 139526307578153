import { isUndefined } from 'lodash';
import { TemplateInputType } from '../../../lib/interfaces/dynamicContent';
import {
  DraftTemplateInputParameter,
  DraftTemplateNumericInput,
  DraftTemplateOutput,
  OutputType,
} from '../../../lib/interfaces/templates';
import { TabProgress } from '../../types';

interface UpdateSourceContentProgressStateArgs {
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
}
export const updateSourceContentProgressState = ({
  topLevelFolder,
  inventorProject,
  assembly,
}: UpdateSourceContentProgressStateArgs): TabProgress => {
  let progressionState = TabProgress.PARTIALLY_COMPLETED;

  if (topLevelFolder && assembly) {
    progressionState = TabProgress.COMPLETED;
  }
  if (!topLevelFolder && !inventorProject && !assembly) {
    progressionState = TabProgress.EMPTY;
  }
  return progressionState;
};

export const updateParametersProgressState = (
  parameters: DraftTemplateInputParameter[],
): TabProgress => {
  let progressionState = TabProgress.EMPTY;

  if (parameters.length) {
    const numericVisibleParameters = parameters.filter(
      (parameter) =>
        parameter.type === TemplateInputType.Numeric && parameter.visible && !parameter.readOnly,
    ) as DraftTemplateNumericInput[];
    // To consider Parameters completed, we must have the required fields filled
    // For now, the only required fields are min and max from NumericInput
    const isParametersCompleted = numericVisibleParameters.every(
      (numericParameter: DraftTemplateNumericInput) =>
        !isUndefined(numericParameter.min) &&
        isFinite(numericParameter.min) &&
        !isUndefined(numericParameter.max) &&
        isFinite(numericParameter.max),
    );

    if (!isParametersCompleted) {
      progressionState = TabProgress.PARTIALLY_COMPLETED;
    } else {
      progressionState = TabProgress.COMPLETED;
    }
  }

  return progressionState;
};

export const updateOutputsProgressState = (outputs: DraftTemplateOutput[]): TabProgress => {
  let progressionState = TabProgress.EMPTY;

  const outputsMap = outputs.reduce(
    (acc: { [key: string]: DraftTemplateOutput }, output: DraftTemplateOutput) => {
      acc[output.type] = output;
      return acc;
    },
    {},
  );

  // For now, we verify RFA output if there is one RFA completed output...
  const rfaOutput = outputsMap[OutputType.RFA];
  const isRFAOutputValid =
    rfaOutput &&
    rfaOutput.options?.category &&
    rfaOutput.options.family &&
    rfaOutput.options.modelStates?.length;

  // and one BOM output...
  const bomOutput = outputsMap[OutputType.BOM];
  const isBOMOutputValid =
    bomOutput &&
    bomOutput.options &&
    bomOutput.options.modelStates &&
    bomOutput.options.modelStates?.length;

  if (isRFAOutputValid && isBOMOutputValid) {
    progressionState = TabProgress.COMPLETED;
  } else if (rfaOutput || bomOutput) {
    progressionState = TabProgress.PARTIALLY_COMPLETED;
  }

  return progressionState;
};
