import { ICON_TYPES } from '@adsk/alloy-react';
import { TreeItem } from '../../../Common/components/MIDTree/MIDTree.types';
import { ProjectFolder } from '../../../lib/interfaces/forge-api';
import { getProjectFolders } from '../../../lib/utils/workspace';

export const getSubFolders = async (
  projectId: string,
  selectedFolderUrn: string,
): Promise<TreeItem[]> => {
  const subFolders: ProjectFolder[] = await getProjectFolders(projectId, selectedFolderUrn);
  const subFoldersDropdownItems: TreeItem[] = subFolders.map((folder) => {
    const isExpandable = folder.hasSubfolders;
    return {
      // The id must be the urn
      id: folder.urn,
      label: folder.title,
      value: folder.urn,
      isExpandable,
      iconType: ICON_TYPES.FOLDER_FILLED,
      children: [],
    };
  });
  return subFoldersDropdownItems;
};
