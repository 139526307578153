import { WorkBook } from 'xlsx';
import {
  DraftTemplate,
  DraftTemplateInputParameter,
  DraftTemplateIProperty,
  DraftTemplateOutput,
  MetaInfo,
  MetaInfoPath,
  OutputType,
} from '../../../lib/interfaces/templates';

export enum CurrentDraftTemplateActionTypes {
  SET_DRAFT = 'SET_DRAFT',
  SET_NAME = 'SET_NAME',
  SET_SOURCE_MODEL = 'SET_SOURCE_MODEL',
  SET_TABLE = 'SET_TABLE',
  SET_PUBLISH_LOCATION = 'SET_PUBLISH_LOCATION',

  ADD_PARAMETERS = 'ADD_PARAMETERS',
  ADD_IPROPERTIES = 'ADD_IPROPERTIES',

  UPDATE_PARAMETER = 'UPDATE_PARAMETER',
  UPDATE_IPROPERTY = 'UPDATE_IPROPERTY',
  UPDATE_TABLE = 'UPDATE_TABLE',
  UPDATE_OUTPUT = 'UPDATE_OUTPUT',

  RESET_DRAFT = 'RESET_DRAFT',
  DELETE_TABLE = 'DELETE_TABLE',
}

type SetCurrentDraft = {
  type: CurrentDraftTemplateActionTypes.SET_DRAFT;
  payload: {
    draft: DraftTemplate;
  };
};

type ResetCurrentDraft = {
  type: CurrentDraftTemplateActionTypes.RESET_DRAFT;
};

type SetCurrentDraftName = {
  type: CurrentDraftTemplateActionTypes.SET_NAME;
  payload: {
    newDraftName: string;
  };
};

type SetCurrentDraftSourceModel = {
  type: CurrentDraftTemplateActionTypes.SET_SOURCE_MODEL;
  payload: {
    topLevelFolder: string;
    inventorProject: string;
    assembly: string;
    thumbnail: string;
  };
};

type SetCurrentDraftTable = {
  type: CurrentDraftTemplateActionTypes.SET_TABLE;
  payload: {
    tableFile: string;
    data: WorkBook;
  };
};

type SetCurrentDraftPublishLocation = {
  type: CurrentDraftTemplateActionTypes.SET_PUBLISH_LOCATION;
  payload: {
    account: MetaInfo;
    project: MetaInfo;
    folder: MetaInfoPath;
  };
};

type AddCurrentDraftParameters = {
  type: CurrentDraftTemplateActionTypes.ADD_PARAMETERS;
  payload: {
    newParameters: DraftTemplateInputParameter[];
  };
};

type AddCurrentDraftIProperties = {
  type: CurrentDraftTemplateActionTypes.ADD_IPROPERTIES;
  payload: {
    newIProperties: DraftTemplateIProperty[];
  };
};

type UpdateCurrentDraftParameter = {
  type: CurrentDraftTemplateActionTypes.UPDATE_PARAMETER;
  payload: {
    parameterToUpdate: DraftTemplateInputParameter;
    updatedValue: { [key: string]: string | boolean | number };
  };
};

type UpdateCurrentDraftIProperty = {
  type: CurrentDraftTemplateActionTypes.UPDATE_IPROPERTY;
  payload: {
    iPropertyToUpdate: DraftTemplateIProperty;
    updatedValue: { [key: string]: string };
  };
};

type DeleteCurrentDraftTable = {
  type: CurrentDraftTemplateActionTypes.DELETE_TABLE;
};

type updateCurrentDraftOutput = {
  type: CurrentDraftTemplateActionTypes.UPDATE_OUTPUT;
  payload: {
    outputType: OutputType;
    updatedValue: DraftTemplateOutput;
  };
};

export type CurrentDraftTemplateActions =
  | SetCurrentDraft
  | ResetCurrentDraft
  | SetCurrentDraftName
  | SetCurrentDraftSourceModel
  | SetCurrentDraftTable
  | SetCurrentDraftPublishLocation
  | AddCurrentDraftParameters
  | AddCurrentDraftIProperties
  | UpdateCurrentDraftParameter
  | UpdateCurrentDraftIProperty
  | updateCurrentDraftOutput
  | DeleteCurrentDraftTable;
