import { BlocklyState } from './BlocklyModule.types';
import text from '../../../Common/global/text/text.json';
import { isUndefined } from 'lodash';

interface blocklyMinMaxInputs {
  name: string;
  min?: number;
  max?: number;
  increment?: number;
}

interface blocklyBooleanLabelsInputs {
  name: string;
  trueLabel?: string;
  falseLabel?: string;
}

const generateMinMaxBlock = (input: blocklyMinMaxInputs): BlocklyState => ({
  type: 'input_set',
  id: `${input.name}_input_set`,
  extraState: {
    dropdown_value: input.name,
  },
  inputs: {
    NAME: {
      block: {
        type: 'set_min_max',
        id: `${input.name}_min_max`,
        inputs: {
          min: {
            ...(!isUndefined(input.min) && {
              block: {
                type: 'math_number',
                id: `${input.name}_min`,
                fields: {
                  NUM: input.min,
                },
              },
            }),
          },
          max: {
            ...(!isUndefined(input.max) && {
              block: {
                type: 'math_number',
                id: `${input.name}_max`,
                fields: {
                  NUM: input.max,
                },
              },
            }),
          },
          increment: {
            ...(!isUndefined(input.increment) && {
              block: {
                type: 'math_number',
                id: `${input.name}_increment`,
                fields: {
                  NUM: input.increment,
                },
              },
            }),
          },
        },
      },
    },
  },
});

const generateBooleanLabelsBlock = (input: blocklyBooleanLabelsInputs): BlocklyState => ({
  type: 'input_set',
  id: `${input.name}_input_set`,
  extraState: {
    dropdown_value: input.name,
  },
  inputs: {
    NAME: {
      block: {
        type: 'set_boolean_labels',
        id: `${input.name}_boolean_labels`,
        inputs: {
          true_value: {
            block: {
              type: 'text',
              id: `${input.name}_true`,
              fields: {
                TEXT: input.trueLabel || text.inputLabelBooleanTrue,
              },
            },
          },
          false_value: {
            block: {
              type: 'text',
              id: `${input.name}_false`,
              fields: {
                TEXT: input.falseLabel || text.inputLabelBooleanFalse,
              },
            },
          },
        },
      },
    },
  },
});

export { generateMinMaxBlock, generateBooleanLabelsBlock };
