import { SetupWorkerApi } from 'msw';
import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalStyle } from '../Common/global/styles/Common/Common.styles';
import { AddInWindow } from '../Common/global/types';
import mockHostApi from '../lib/mocks/hostApi';
import App from './App';
import reportWebVitals from '../Common/global/reportWebVitals';
import { AsyncProviderConfig, asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

if (process.env.NODE_ENV === 'development' && process.env.LOCAL_DEVELOPMENT === 'browser') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const mockWorker: SetupWorkerApi = require('../lib/mocks/serviceWorker').default;

  // Starting msw mock worker to intercept FORGE API calls
  mockWorker.start();

  // Mocking HostApi library
  (window as AddInWindow).hostApi = mockHostApi;
}

const LDProviderConfig: AsyncProviderConfig = {
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID!,
  options: {
    streaming: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: true,
  },
};

(async () => {
  const LDProvider = await asyncWithLDProvider(LDProviderConfig);
  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyle />
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
