import { Icon, TextInput, Tooltip } from '@adsk/alloy-react';
import React from 'react';
import {
  FieldSetContainer,
  FieldsetRow,
  InputFormField,
  RowItem,
  TooltipIcon,
  TooltipWrapper,
} from '../../../Common/global/styles/Common/Common.styles';
import text from '../../../Common/global/text/text.json';
import { DraftTemplateIProperty } from '../../../lib/interfaces/templates';
import useInputLabel from '../../hooks/useInputLabel';
import { CallInputDataStoreUpdateHandlerArgs } from '../../types';

export interface IPropertyInfoProps {
  iProperty: DraftTemplateIProperty;
  callInputDataStoreUpdateHandler: CallInputDataStoreUpdateHandlerArgs;
}

export const IPropertyInfo: React.FC<IPropertyInfoProps> = ({
  iProperty,
  callInputDataStoreUpdateHandler,
}): JSX.Element => {
  const { label, name, category, value } = iProperty;
  const { localInputLabel, handleLabelChange } = useInputLabel({
    label,
    callInputDataStoreUpdateHandler,
  });

  return (
    <>
      <FieldSetContainer>
        <FieldsetRow>
          <RowItem>
            <InputFormField label={text.editInputsiPropertyName} labelVariant="top" width="100%">
              <TextInput value={name} disabled />
            </InputFormField>
            <InputFormField
              label={
                <>
                  {text.inputsLabel}
                  <TooltipWrapper>
                    <Tooltip content={text.editInputsLabelTooltip}>
                      <TooltipIcon type={Icon.TYPES.QUESTION_CIRCLE_FILLED} size={16} fr={0} />
                    </Tooltip>
                  </TooltipWrapper>
                </>
              }
              labelVariant="top"
              width="100%"
            >
              <TextInput key={name} value={localInputLabel} onChange={handleLabelChange} />
            </InputFormField>
          </RowItem>
          <RowItem justifyContent="flex-end">
            <InputFormField label={text.editInputsiPropertyType} labelVariant="top" width="40%">
              <TextInput value={category} disabled />
            </InputFormField>
          </RowItem>
        </FieldsetRow>
        <FieldsetRow>
          <RowItem>
            <InputFormField label={text.editInputsiPropertyValue} labelVariant="top" width="50%">
              <TextInput value={value} disabled />
            </InputFormField>
          </RowItem>
        </FieldsetRow>
      </FieldSetContainer>
    </>
  );
};
