import styled from 'styled-components';

export const ItemCount = styled.p`
  // TODO: Can't use var because var includes padding
  height: 20px;
  margin: ${({ theme }) => `${theme.vars.marginBase * 1.4}px`};
  ${({ theme }) => theme.typography.bodyMedium}
  text-align: right;
`;

export const EditInputsContainer = styled.form`
  margin-top: ${({ theme }) => `${theme.vars.marginBase}px`};
  overflow: auto;
  max-height: calc(
    100vh - ${({ theme }) => `${theme.vars.topBars}px`} -
      ${({ theme }) => `${theme.vars.editInputsContainerHeight}px`}
  );
`;
