import { CheckboxState } from '@adsk/alloy-react';
import React from 'react';
import text from '../../../Common/global/text/text.json';
import CheckboxWithLabelText from '../CheckboxWithLabelText/CheckboxWithLabelText';

export interface ValueConfigurationHeaderProps {
  showInputInForm: boolean;
  readOnly: boolean;
  handleShowInputInFormChange: (state: CheckboxState) => void;
  handleReadOnlyChange: (state: CheckboxState) => void;
}

export const ValueConfigurationHeader: React.FC<ValueConfigurationHeaderProps> = ({
  showInputInForm,
  readOnly,
  handleShowInputInFormChange,
  handleReadOnlyChange,
}) => (
  <>
    <CheckboxWithLabelText
      checked={showInputInForm}
      handleCheckboxChange={handleShowInputInFormChange}
    >
      {text.inputLabelShowInForm}
    </CheckboxWithLabelText>
    {showInputInForm && (
      <CheckboxWithLabelText
        data-testid="read-only-checkbox"
        checked={readOnly}
        handleCheckboxChange={handleReadOnlyChange}
      >
        {text.inputLabelReadOnly}
      </CheckboxWithLabelText>
    )}
  </>
);
