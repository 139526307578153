import { Column, SetTableOptions } from '@adsk/alloy-react-table';
import { StateReducer } from '@adsk/alloy-react-table/es/stateReducer';
import React, { ReactNode } from 'react';
import { MIDEmptyState } from '../../../../../Common/components/EmptyState/MIDEmptyState';
import { InventorParameter, IProperty } from '../../../../../lib/interfaces/inventorProperties';
import { InputsSelectionTable } from './InputsSelection.styles';

export interface ParametersTableProps {
  tableData: InventorParameter[] | IProperty[];
  tableColumns: Column<Record<string, InventorParameter>>[] | Column<Record<string, IProperty>>[];
  setTableOptions: SetTableOptions<Record<string, any>>;
  selectedIds: { [key: string]: boolean };
  handleOnAction: (reducer: StateReducer<Record<string, any>>) => void;
  renderEmptyState?: (props: any) => ReactNode;
}

export const InputsTable: React.FC<ParametersTableProps> = ({
  tableData,
  tableColumns,
  setTableOptions,
  selectedIds,
  handleOnAction,
  renderEmptyState = () => <MIDEmptyState />,
}): JSX.Element => (
  // FIXME: Sort order resets after selecting row
  <InputsSelectionTable
    columns={tableColumns as Column<Record<string, unknown>>[]}
    hasStickyHeader
    data={tableData as Record<string, any>[]}
    isMultiSelectable
    isResizable
    isSortable
    setTableOptions={setTableOptions}
    selectedRowIds={selectedIds}
    onAction={handleOnAction}
    renderEmptyState={renderEmptyState}
  />
);

export default InputsTable;
