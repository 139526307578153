import {
  actions,
  OnAction,
  SetTableOptions,
  TableSkeletonLoadingRow,
} from '@adsk/alloy-react-table';
import { format } from 'date-fns';
import React, { ReactNode, useState } from 'react';
import logger from '../../../Common/global/logger';
import { getFullFolderNamedPath } from '../../../lib/utils/drafts';
import { DraftTableDataModel } from './DraftTable.types';
import { MIDEmptyState } from '../../../Common/components/EmptyState/MIDEmptyState';
import text from '../../../Common/global/text/text.json';
import { renderEmptyStateArgs } from '../../../Common/global/types';
import { StyledSkeletonLoadingVertebra } from '../../../Common/global/styles/Common/Common.styles';
import { DraftTableProps } from './DraftTable';

interface UseDraftTableProps {
  tableData: DraftTableDataModel[];
  selectedDraftIds: Record<string, boolean>;
  numberOfDraftsSelected: number;
  setTableOptions: SetTableOptions<Record<string, any>>;
  handleEditTemplateClickWithContext: () => void;
  handleDeleteTemplateClickWithContext: () => void;
  onAction: OnAction<Record<string, any>>;
  renderEmptyState: ({ table }: renderEmptyStateArgs) => ReactNode;
}

const useDraftTable = ({
  drafts,
  isFetching,
  handleEditTemplateClick,
  handleDeleteTemplatesClick,
}: DraftTableProps): UseDraftTableProps => {
  const [selectedDraftIds, setSelectedDraftIds] = useState<Record<string, boolean>>({});
  const numberOfDraftsSelected = Object.keys(selectedDraftIds).length;

  const setTableOptions: SetTableOptions<Record<string, any>> = (opts: any) => ({
    ...opts,
    getRowId: (row: DraftTableDataModel) => row.id,
  });

  const tableData: DraftTableDataModel[] = drafts
    ? drafts.map((d) => ({
        templateName: d.name,
        numberOfInputs: d.parameters.length + d.iProperties.length,
        publishTo:
          d.account.name && d.project.name
            ? `${d.account.name} / ${d.project.name} / ${getFullFolderNamedPath(d.folder)}`
            : text.draftTableColumnPublishToNotPublished,
        lastUpdated: format(d.lastUpdated, 'MMM d, yyyy h:m a'),
        id: d.id,
      }))
    : [];

  const handleEditTemplateClickWithContext = (): void => {
    if (numberOfDraftsSelected !== 1) {
      // TODO: Replace error thrown with Error modal
      // once we have it
      throw new Error('Cannot edit more than one Draft at a time.');
    }

    const draftId = Object.keys(selectedDraftIds)[0];
    const draft = drafts?.find((draft) => draft.id === draftId);
    if (draft) {
      handleEditTemplateClick(draft);
    } else {
      // TODO: Replace error thrown with Error modal
      // once we have it
      throw new Error('Could not find the Draft based on the currently selected row.');
    }
  };
  const handleDeleteTemplateClickWithContext = (): void => {
    try {
      const draftIds: string[] = Object.keys(selectedDraftIds);
      handleDeleteTemplatesClick(draftIds);
      setSelectedDraftIds({});
    } catch (err) {
      logger.error('Fail to delete templates with context.', { err });
      throw err;
    }
  };

  const onAction: OnAction<Record<string, any>> = ({ action, state }) => {
    if (action.type === actions.toggleRowSelected) {
      setSelectedDraftIds(state.selectedRowIds);
    }
    if (action.type === actions.toggleAllRowsSelected) {
      setSelectedDraftIds(state.selectedRowIds);
    }
  };

  const renderEmptyState = ({ table }: renderEmptyStateArgs) => {
    if (isFetching) {
      return (
        <TableSkeletonLoadingRow
          visibleColumns={table.visibleColumns}
          renderCell={() => <StyledSkeletonLoadingVertebra />}
        />
      );
    }
    return (
      <MIDEmptyState
        title={text.EmptyStateNoDraftTemplates}
        description={text.EmptyStatePleaseCreateDraftTemplates}
      />
    );
  };

  return {
    tableData,
    selectedDraftIds,
    numberOfDraftsSelected,
    setTableOptions,
    handleEditTemplateClickWithContext,
    handleDeleteTemplateClickWithContext,
    onAction,
    renderEmptyState,
  };
};

export default useDraftTable;
