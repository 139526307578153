import React, { useContext } from 'react';
import NavigationContext from '../../context/NavigationStore/Navigation.context';
import { Screens } from '../../context/NavigationStore/navigationStore';
import { DraftConfigurationScreen } from '../../screens/DraftConfigurationScreen';
import { DraftTemplatesScreen } from '../../screens/DraftTemplatesScreen';
import { Publishing } from '../Publishing/Publishing';

export const ScreenManager: React.FC = (props) => {
  const { currentScreen } = useContext(NavigationContext);

  if (currentScreen === Screens.DRAFT_TEMPLATES) {
    return <DraftTemplatesScreen {...props} />;
  }
  if (currentScreen === Screens.DRAFT_CONFIGURATION) {
    return <DraftConfigurationScreen {...props} />;
  }
  if (currentScreen === Screens.PUBLISHING) {
    return <Publishing {...props} />;
  }
  return <DraftTemplatesScreen {...props} />;
};
