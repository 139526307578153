import { Button, Icon, IconType } from '@adsk/alloy-react';
import { isUndefined } from 'lodash';
import React from 'react';
import {
  SidebarComponent,
  SidebarButton,
  SidebarMenu,
  SidebarItem,
} from '../../../../../../Common/global/styles/Common/Common.styles';
import text from '../../../../../../Common/global/text/text.json';
import { TemplateInputType } from '../../../../../../lib/interfaces/dynamicContent';
import {
  DraftTemplateInputParameter,
  DraftTemplateInputProperties,
  DraftTemplateIProperty,
} from '../../../../../../lib/interfaces/templates';
import { ItemCount } from '../EditInputs.styles';

export interface EditInputsSidebarProps {
  selectedInput: DraftTemplateInputProperties;
  selectedParameterInfo: DraftTemplateInputParameter | null;
  selectediPropertyInfo: DraftTemplateIProperty | null;
  handleChangeSelectedInputs: () => void;
  handleParameterSelection: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleIPropertySelection: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface RequiredIconsMap {
  REQUIRED_COMPLETED: IconType;
  REQUIRED_MISSING: IconType;
}

interface ParameterIconAndTooltip {
  icon: IconType;
  tooltipText: string;
}

const requiredIconsMap: RequiredIconsMap = {
  REQUIRED_COMPLETED: Icon.TYPES.CHECKMARK_CIRCLE_FILLED,
  REQUIRED_MISSING: Icon.TYPES.ALERT_WARNING_FILLED,
};

export const EditInputsSidebar: React.FC<EditInputsSidebarProps> = ({
  selectedInput,
  selectedParameterInfo,
  selectediPropertyInfo,
  handleChangeSelectedInputs,
  handleParameterSelection,
  handleIPropertySelection,
}): JSX.Element => {
  const countSelectedItems = (): number =>
    selectedInput.iProperties.length + selectedInput.parameters.length;

  const handlePreviewRules = () => undefined;

  const getParameterIconAndTooltip = (
    parameter: DraftTemplateInputParameter,
  ): ParameterIconAndTooltip | undefined => {
    // Input Numeric has the following required fields: `min` and `max`
    if (parameter.type === TemplateInputType.Numeric && parameter.visible && !parameter.readOnly) {
      if (!isUndefined(parameter.min) && !isUndefined(parameter.max)) {
        return {
          icon: requiredIconsMap.REQUIRED_COMPLETED,
          tooltipText: text.editInputsRequiredFieldsCompleted,
        };
        return;
      }
      return {
        icon: requiredIconsMap.REQUIRED_MISSING,
        tooltipText: text.editInputsRequiredFieldsMissing,
      };
    }
  };

  return (
    <SidebarComponent>
      <SidebarButton
        size={Button.SIZES.SMALL}
        onClick={handleChangeSelectedInputs}
        icon={Icon.TYPES.PLUS_CIRCLE_FILLED}
        variant={Button.VARIANTS.PRIMARY}
      >
        {text.buttonChangeInputs}
      </SidebarButton>
      <SidebarButton
        size={Button.SIZES.SMALL}
        onClick={handlePreviewRules}
        icon={Icon.TYPES.CALCULATOR}
      >
        {text.buttonPreviewRules}
      </SidebarButton>
      <SidebarMenu>
        {selectedInput.parameters.map((parameter) => {
          const isSelected = selectedParameterInfo?.name === parameter.name;
          const parameterIconAndTooltip = getParameterIconAndTooltip(parameter);

          return (
            <SidebarItem
              showSelection={false}
              selected={isSelected}
              key={parameter.name}
              label={parameter.name}
              name={parameter.name}
              onClick={handleParameterSelection}
              {...(parameterIconAndTooltip && {
                icon: parameterIconAndTooltip.icon,
                title: parameterIconAndTooltip.tooltipText,
              })}
            />
          );
        })}
        {selectedInput.iProperties.map((iProperty) => {
          const isSelected = !selectedParameterInfo && selectediPropertyInfo?.id === iProperty.id;
          return (
            <SidebarItem
              showSelection={false}
              selected={isSelected}
              key={iProperty.id}
              label={iProperty.name}
              icon={Icon.TYPES.PENCIL_FILLED}
              id={iProperty.id}
              onClick={handleIPropertySelection}
            />
          );
        })}
      </SidebarMenu>
      <ItemCount>{countSelectedItems()} selected</ItemCount>
    </SidebarComponent>
  );
};
