import React from 'react';
import { BlocklyPlaceholder } from './BlocklyModule.styles';
import useGenerateBlocklyState from './useGenerateBlocklyState';
import useBlocklyModule from './useBlocklyModule';

const BlocklyModule: React.FC = (): JSX.Element => {
  const { inputState } = useGenerateBlocklyState();
  const { ref } = useBlocklyModule({ initialState: inputState });
  return <BlocklyPlaceholder ref={ref} />;
};

export default BlocklyModule;
