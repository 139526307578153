// These blocks will be updated based on feedback.
// Block definitions can be founnd at: https://wiki.autodesk.com/pages/viewpage.action?spaceKey=MID&title=DCD+Rules+API+for+Blockly

const DCDcustomBlocks = [
  {
    type: 'conditional_rule',
    message0: 'Add rule %1 %2 %3',
    args0: [
      {
        type: 'input_value',
        name: 'expression',
        check: 'Boolean',
      },
      {
        type: 'field_label_serializable',
        name: 'NAME',
        text: 'When',
      },
      {
        type: 'input_statement',
        name: 'rules_list',
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 280,
    tooltip: '',
    helpUrl: '',
  },
  {
    type: 'set_value',
    message0: 'Set value %1 %2',
    args0: [
      {
        type: 'input_dummy',
      },
      {
        type: 'input_value',
        name: 'NAME',
        check: ['Boolean', 'String', 'Number'],
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 150,
    tooltip: '',
    helpUrl: '',
  },
  {
    type: 'set_min_max',
    message0: 'Set %1 min %2 max %3 increment %4',
    args0: [
      {
        type: 'input_dummy',
      },
      {
        type: 'input_value',
        name: 'min',
      },
      {
        type: 'input_value',
        name: 'max',
      },
      {
        type: 'input_value',
        name: 'increment',
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 150,
    tooltip: '',
    helpUrl: '',
  },
  {
    type: 'set_visible',
    message0: 'Set visible %1',
    args0: [
      {
        type: 'field_checkbox',
        name: 'visible',
        checked: true,
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 150,
    tooltip: '',
    helpUrl: '',
  },
  {
    type: 'set_readonly',
    message0: 'Set read only %1',
    args0: [
      {
        type: 'field_checkbox',
        name: 'readonly',
        checked: true,
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 150,
    tooltip: '',
    helpUrl: '',
  },
  {
    type: 'set_options',
    message0: 'Set options %1',
    args0: [
      {
        type: 'input_value',
        name: 'NAME',
        check: 'Array',
      },
    ],
    previousStatement: null,
    nextStatement: null,
    colour: 150,
    tooltip: '',
    helpUrl: '',
  },
  {
    type: 'set_boolean_labels',
    message0: 'Display TRUE as %1 Display FALSE as %2',
    args0: [
      {
        type: 'input_value',
        name: 'true_value',
      },
      {
        type: 'input_value',
        name: 'false_value',
      },
    ],
    inputsInline: true,
    previousStatement: null,
    nextStatement: null,
    colour: 150,
    tooltip: '',
    helpUrl: '',
  },
];

export default DCDcustomBlocks;
